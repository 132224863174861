import RenderUtils from '@wix/photography-client-lib/dist/src/utils/renderUtils';
import { GALLERY_CONSTS } from 'pro-gallery';
import * as lodash from './lodash';

export class Utils extends RenderUtils {
  /* @ngInject */
  constructor() {
    super();
    this.init();
  }
  init = () => {
    Object.assign(this, lodash);
  };
  static parseViewMode(viewMode) {
    switch (viewMode.toLowerCase()) {
      case 'editor':
        return GALLERY_CONSTS.viewMode.EDIT;
      case 'preview':
        return GALLERY_CONSTS.viewMode.PREVIEW;
      case 'site':
      default:
        return GALLERY_CONSTS.viewMode.SITE;
    }
  }

  static parseFormFactor(formFactor) {
    switch (formFactor.toLowerCase()) {
      case 'tablet':
        return GALLERY_CONSTS.formFactor.TABLET;
      case 'mobile':
        return GALLERY_CONSTS.formFactor.MOBILE;
      case 'desktop':
      default:
        return GALLERY_CONSTS.formFactor.DESKTOP;
    }
  }
}

export const utils = new Utils();
